import adminLayout from '@/layouts/Admin'
import currentRouteMixin from '@/mixins/current-route.js'
import httpAxios from '@/utils/http-axios.js'
import optionsMixin from './mixins/options.js'
import previewFileAudioComponent from '@/components/PreviewFileAudio'
import previewFileImageComponent from '@/components/PreviewFileImage'

export default {
    name: 'QuestionsView',
    data() {
        return {
            question: {},
            currentQuestionId: null,
            fileAudio: null
        }
    },
    mixins: [currentRouteMixin, optionsMixin],
    components: {
        adminLayout,
        previewFileAudioComponent,
        previewFileImageComponent
    },
    mounted() {
        this.currentQuestionId = this.currentRoute().params.id

        this.getQuestion()
    },
    methods: {
        updateQuestion() {
            const self = this
            let formData = new FormData()

            formData.append('_method', 'PUT')
            if(self.question.title) formData.append('title', self.question.title)
            if(self.fileAudio) formData.append('audio', self.fileAudio)

            httpAxios({
                url: self.$backendUrl + '/api/v1/questions/' + self.currentQuestionId,
                method: 'POST',
                data: formData
            }).then(function (response) {
                self.question = response.data.question

                self.notifySuccess()
            })
        },
        getQuestion() {
            const self = this
            const selects = '?select=id,title,audio'
            const includes = '&include=options'

            httpAxios({
                url: self.$backendUrl + '/api/v1/questions/' + self.currentQuestionId + selects + includes,
                method: 'GET'
            }).then(function (response) {
                self.question = response.data.question
                self.options = response.data.question.options
            })
        },
        handleFileAudioUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAudio = null
            }

            this.fileAudio = files[0]
        }
    }
}
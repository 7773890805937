import httpAxios from '@/utils/http-axios.js'

export default {
    data() {
        return {
            options: null,
            optionSelected: null
        }
    },
    methods: {
        addNewOption() {
            if (this.options.length >= 3) {
                return this.$notify({
                    group: 'notify',
                    type: 'error',
                    title: 'Error',
                    text: 'You can add only 3 options!'
                })
            }

            this.options.push({ image: null, is_right: 0, id: null, audio: null })
        },
        handleOptionFileUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) return

            this.options[this.optionSelected].file = files[0]
        },
        handleOptionFileAudioUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) return

            this.options[this.optionSelected].fileAudio = files[0]
        },
        prepareEditOption(index) {
            this.optionSelected = index
        },
        saveOption() {
            const self = this
            const option = self.options[self.optionSelected]
            const url = (option.id) ? (self.$backendUrl + '/api/v1/questions-options/' + option.id) : (self.$backendUrl + '/api/v1/questions-options')
            let formData = new FormData()
            if (option.id) formData.append('_method', 'PUT')
            if (option.file) formData.append('image', option.file)
            if (option.fileAudio) formData.append('audio', option.fileAudio)
            if (option.title) formData.append('title', option.title)
            if (!option.id) formData.append('title', 'WIP')
            formData.append('question_id', this.currentQuestionId)
            formData.append('is_right', option.is_right)

            httpAxios({
                url: url,
                method: 'POST',
                data: formData
            }).then(function (response) {
                self.options[self.optionSelected] = response.data.question_option

                self.notifySuccess()

                self.optionSelected = null
            })
        },
        deleteOption(index) {
            const self = this
            const id = self.options[index].id ?? null

            if (!id) {
                return self.options.splice(index, 1)
            }

            httpAxios({
                url: self.$backendUrl + '/api/v1/questions-options/' + id,
                method: 'DELETE'
            }).then(function () {
                self.options.splice(index, 1)
            })
        },
        cancelEditOption() {
            this.optionSelected = null
        }
    }
}